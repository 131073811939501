<!-- eslint-disable vue/valid-v-on -->

<script>
// import ThemeSwitcher from '../ThemeSwitcher';
import HireMeModal from '../HireMeModal.vue';
import feather from 'feather-icons';
import AppHeaderLinks from './AppHeaderLinks.vue';

export default {
	components: {
		// ThemeSwitcher,
		HireMeModal,
		AppHeaderLinks,
	},
	data() {
		return {
			isScroll: false,
			isOpen: false,
			theme: '',
			modal: false,
			categories: [
				{
					id: 1,
					value: 'web',
					name: 'Web Application',
				},
				{
					id: 2,
					value: 'mobile',
					name: 'Mobile Application',
				},
				{
					id: 3,
					value: 'ui-ux',
					name: 'UI/UX Design',
				},
				{
					id: 4,
					value: 'branding',
					name: 'Branding & Anim',
				},
			],
		};
	},

	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
		window.addEventListener("scroll", this.listenScrollEvent);
	},
	unmount() {
		window.removeEventListener("scroll", this.listenScrollEvent);
	},
	methods: {
		listenScrollEvent() {
			if (window.scrollY > 200) {
				this.isScroll = true
			} else {
				this.isScroll = false
			}
		},
		updateTheme(theme) {
			this.theme = theme;
		},
		showModal() {
			if (this.modal) {
				// Stop screen scrolling
				document
					.getElementsByTagName('html')[0]
					.classList.remove('overflow-y-hidden');
				this.modal = false;
			} else {
				document
					.getElementsByTagName('html')[0]
					.classList.add('overflow-y-hidden');
				this.modal = true;
			}
		},
	},
	watch: {
		$route() {
			this.isOpen = false
		}
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<!--:class="{ 'bg-[#111]': isScroll, 'bg-[#111]': !isScroll }"-->
	<header class="bg-black sm:bg-transparent sm:absolute w-full z-50 left-0 right-0" :style="{transition: 'all 1s'}">
		<nav id="nav" class="sm:container sm:mx-auto ">
			<!-- Header start -->
			<div class="z-10 max-w-screen-lg xl:max-w-screen-xl block sm:flex sm:justify-between sm:items-center py-6">
				<!-- Header menu links and small screen hamburger menu -->
				<div class="flex justify-between items-center px-4 sm:px-0">
					<!-- Header logos -->
					<div>
						<router-link to="/"><img v-if="theme === 'light'" alt="India IYDF-British IYDF" src="@/assets/images/logo.webp" class="w-28 sm:w-40"
								/>
							<img v-else src="@/assets/images/logo.webp" class="w-28 sm:w-40" alt="India IYDF-British IYDF" />
						</router-link>
					</div>

					<!-- Theme switcher small screen -->
					<!-- <theme-switcher :theme="theme" @themeChanged="updateTheme"
						class="block sm:hidden bg-ternary-light dark:bg-ternary-dark hover:bg-hover-light dark:hover:bg-hover-dark hover:shadow-sm px-2.5 py-2 rounded-lg" /> -->

					<!-- Small screen hamburger menu -->
					<div class="sm:hidden">
						<button @click="isOpen = !isOpen" type="button" class="focus:outline-none" aria-label="Hamburger Menu">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
								class="h-7 w-7 fill-current text-ternary-light dark:text-ternary-light">
								<path v-if="isOpen" fill-rule="evenodd"
									d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
									clip-rule="evenodd"></path>
								<path v-if="!isOpen" fill-rule="evenodd"
									d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z">
								</path>
							</svg>
						</button>
					</div>
				</div>

				<!-- Header links -->
				<AppHeaderLinks :showModal="showModal" :isOpen="isOpen" />

			</div>

			<!-- Hire me modal -->
			<HireMeModal :showModal="showModal" :modal="modal" :categories="categories" aria-modal="Hire Me Modal" />
		</nav>
	</header>
</template>

<style scoped>
#nav a.router-link-exact-active {
	@apply text-indigo-700;
	@apply dark:text-indigo-400;
	@apply font-medium;
}
</style>
