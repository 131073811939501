<script>
	export default {
		props: ['showModal', 'isOpen'],
		data() {
		return {
			accountss: null,
		};
	},
		computed: {
			isShow() {
				if (this.$route.name == 'Login' || this.$route.name == 'Register' || this.$route.name == 'ResetPwd') {
					return false
				}
				return true
			}
		},
		watch: {
			$route() {
				setTimeout(() => {
					if(localStorage.getItem('userInfo')){
						this.accountss = JSON.parse(localStorage.getItem('userInfo')).account
					}
				}, 3000)
				
			}
		},
		methods: {
			loginOut(){
				localStorage.removeItem('userInfo')
				localStorage.removeItem('token')
				this.$router.push('/')
				this.accountss = null
				
			},
			isMobile() {
				let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
				return flag;
			}
		}
			
	};
</script>

<template>
	<!-- Header links -->
	<div :class="isOpen ? 'block' : 'hidden'"
		class="m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none">
		<router-link to="/"
			title="Home- IYDF British International Youth Development Foundation-India IYDF International Youth Development Foundation-IYDF"
			class="font-general-medium block text-left text-lg font-bold text-[#b7b4b4] sm:text-white dark:text-ternary-light hover:text-white sm:hover:text-[#111] dark:hover:text-indigo-300  sm:mx-4 mb-2 sm:py-2 "
			aria-label="Projects">Home</router-link>
		<!-- <router-link to="/projects"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300  sm:mx-4 mb-2 sm:py-2 "
			aria-label="Projects">Projects</router-link> -->
		<router-link to="/about"
			v-if="isShow"
			title="About UsI-India IYDF British International Youth Development Foundation-IYDF International Youth Development Foundation-IYDF"
			class="font-general-medium block text-left text-lg font-bold text-[#b7b4b4] sm:text-white dark:text-ternary-light hover:text-white sm:hover:text-[#111] dark:hover:text-indigo-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-[#b7b4b4] dark:border-secondary-dark"
			aria-label="About Me">About Us</router-link>
		<router-link to="/platform"
			v-if="isShow"
			title="Eco Network- IYDF British International Youth Development Foundation-BritishI YDF International Youth Development Foundation-IYDF"
			class="font-general-medium block text-left text-lg font-bold text-[#b7b4b4] sm:text-white dark:text-ternary-light hover:text-white sm:hover:text-[#111] dark:hover:text-indigo-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-[#b7b4b4] dark:border-secondary-dark"
			aria-label="Platform">Eco Network</router-link>
		<router-link to="/contact"
			v-if="isShow"
			title="Join Us-British IYDF British International Youth Development Foundation-IYDF International Youth Development Foundation-IYDF"
			class="font-general-medium block text-left text-lg font-bold text-[#b7b4b4] sm:text-white dark:text-ternary-light hover:text-white sm:hover:text-[#111] dark:hover:text-indigo-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-[#b7b4b4] dark:border-secondary-dark"
			aria-label="Contact">Join Us</router-link>
		<router-link to="/cause"
			v-if="isShow"
			title="Fund Business-IYDF British International Youth Development Foundation-IYDF International Youth Development Foundation-IYDF"
			class="font-general-medium block text-left text-lg font-bold text-[#b7b4b4] sm:text-white dark:text-ternary-light hover:text-white sm:hover:text-[#111] dark:hover:text-indigo-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-[#b7b4b4] dark:border-secondary-dark"
			aria-label="Cause">Fund Business</router-link>

			<router-link to="/contract"
			v-if="isShow"
			title="Contract British International Youth Development Foundation-IYDF International Youth Development Foundation-IYDF"
			class="font-general-medium block text-left text-lg font-bold text-[#b7b4b4] sm:text-white dark:text-ternary-light hover:text-white sm:hover:text-[#111] dark:hover:text-indigo-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-[#b7b4b4] dark:border-secondary-dark"
			aria-label="Contract">Contract</router-link>
		
	
		<el-popover
    placement="bottom"
    trigger="click"
		v-if="!isMobile()&&accountss"
  >
		<el-button  plain style="width:100%;background:#fff" @click="loginOut" >Log out</el-button>
			<template #reference>
				<div v-if="accountss" style="color: #fff;border-radius: 8px;border: solid 1px rgba(255, 255, 255, 0.4);padding:2px 5px" class="border-t-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-light dark:border-secondary-dark">
				{{accountss}}
			</div>
    </template>
  </el-popover>

	<div v-if="isMobile()">
		<div v-if="accountss" style="color: #fff;border-radius: 8px;border: solid 1px rgba(255, 255, 255, 0.4);padding:2px 5px" class="border-t-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-light dark:border-secondary-dark">
				{{accountss}}
			</div>
	</div>

	<div @click="loginOut" v-if="isMobile()&&accountss" style="color: #fff;border-radius: 8px;border: solid 1px rgba(255, 255, 255, 0.4);padding:2px 5px; margin-top:10px" class="border-t-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-light dark:border-secondary-dark">
		Log out
			</div>
	</div>
</template>

<style lang="scss" scoped></style>