import service from "@/utils/request";
export const info = (data) => {
  return service({
    url: "/api/home/info",
    method: "post",
    data,
  });
};

export const setQuestion = (data) => {
  return service({
    url: "/api/home/question",
    method: "post",
    data,
  });
};

export const getContract = (data) => {
  return service({
    url: "/api/contract/list",
    method: "post",
    data,
  });
};
export const setContract = (data) => {
  return service({
    url: "/api/contract/sign",
    method: "post",
    data,
  });
};

export const forgotPassword = (data) => {
  return service({
    url: "/api/base/forgotPassword",
    method: "post",
    data,
  });
};

export const login = (data) => {
  return service({
    url: "/api/base/login",
    method: "post",
    data,
  });
};

export const register = (data) => {
  return service({
    url: "/api/base/register",
    method: "post",
    data,
  });
};

export const getCode = (data) => {
  return service({
    url: "/api/base/smsCode",
    method: "post",
    data,
  });
};

export const getImageCode = (data) => {
  return service({
    url: "/api/base/captcha",
    method: "post",
    data,
  });
};
export const getUserInfo = (data) => {
  return service({
    url: "/api/user/getUserInfo",
    method: "post",
    data,
  });
};
